// _app 에서 로케일 초기 셋텅 훅
import * as React from 'react'
import { useRouter } from 'next/router'
import type { i18n as I18n } from 'i18next'

import createI18n from '@/i18n'
import { LocaleEnum } from '@/types/locale'

import { getLocale } from './storage'

const useLocaleConfig = (): { i18n: I18n } => {
  const { locale } = useRouter() // server 혹은 path 통해서 최초 locale 감지
  const i18n = React.useMemo(() => createI18n({ locale }), [locale])
  const [i18nInstance, setI18n] = React.useState(i18n)

  // 마운트 후 localstorage에 있는 로케일 값으로 설정
  React.useEffect(() => {
    const localLocale = getLocale({ fallback: false })
    if (
      // 로컬스토리지에 별도 언어 설정 값이 없는 경우 서버 로케일 우선
      localLocale !== null &&
      localLocale !== locale &&
      // /en path로 들어올 경우 localstorage(푸터 언어 설정) 설정값 무시
      locale === LocaleEnum.ko
    ) {
      // path에 로케일 prepath가 없는 경우 로컬스토리지 언어설정값 우선
      setI18n(createI18n({ locale: localLocale }))
    }
  }, [locale])

  return { i18n: i18nInstance }
}

export default useLocaleConfig
