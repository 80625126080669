import * as React from 'react'
import { I18nextProvider } from 'react-i18next'

import useLocale from './locale-config-hook'

type Props = {
  children: React.ReactNode
}

const LocaleProvider = ({ children }: Props) => {
  const { i18n } = useLocale()

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export default LocaleProvider
