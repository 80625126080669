import i18n, { i18n as I18n } from 'i18next'
import { initReactI18next } from 'react-i18next'

import { LocaleEnum } from '@/types/locale'
import sendInternationalizationError from '@/service/error-log/internationalization-error'

import languages, { lngs } from './languages'

const { en, ko } = languages

type Props = {
  locale: string | undefined
}

const createI18n = ({ locale }: Props): I18n => {
  i18n.use(initReactI18next).init({
    resources: { en, ko },
    lng: locale === LocaleEnum.ko ? LocaleEnum.ko : LocaleEnum.en,
    fallbackLng: lngs[0],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    saveMissing: true,
    missingKeyHandler: sendInternationalizationError,
  })

  return i18n
}

export default createI18n
