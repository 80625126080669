import sendErrorLog from '.'

class InternationalizationError extends Error {
  constructor(lng: readonly string[], ns: string, key: string, fallbackValue: string) {
    super(`lng: [${lng.join()}], ns: ${ns}, key: ${key}, fallbackValue: ${fallbackValue}`)
    this.name = 'InternationalizationError'
  }
}

export default function sendInternationalizationError(
  lngs: readonly string[],
  ns: string,
  key: string,
  fallbackValue: string,
) {
  if (key === fallbackValue) {
    sendErrorLog(new InternationalizationError(lngs, ns, key, fallbackValue))
  }
}
